<template>
    <front-layout>
        <v-container style="min-height: 500px">
            <v-row>
                <h1 v-html="page.name"></h1>
            </v-row>
            <v-row class="text-center">
                <v-col cols="12">
                    <div v-html="page.description"></div>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../layouts/FrontLayout";
    import Page from "../models/Page";

    export default {
        name: 'about',
        components: {FrontLayout},
        metaInfo() {
            return {
                title: this.$t('meta.about_title'),
                meta: [
                    {name: 'description', content: this.$t('meta.about_description'),}
                ]
            }
        },
        data: function () {
            return {
                page: {},
            }
        },
        async mounted() {
            this.page = await Page.find(1)
        },
    }
</script>
